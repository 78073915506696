import packageInfo from '../../package.json';

const webUrl = 'https://drupal-dev.atedev.co.uk';

export const environment = {
  production: false,
  sentry: {
    dsn: 'https://177107c974814a94965bc634f42b0543@o139805.ingest.sentry.io/4504638031265792',
    tracePropagationTargets: ['localhost', 'https://dev-organiser-web-client.web.app/']
  },
  googleMapsApiKey: 'AIzaSyA7ZlDJ0LjfutjZH-PeqAHS5XYxdYQYPEI',
  apiUrl: 'https://dev.ateapi.co.uk',
  // apiUrl: 'http://localhost:8080',
  version: packageInfo.version,
  webUrl: 'https://drupal-dev.atedev.co.uk',
  logoutUrl: `${webUrl}/user/logout`,
  supplierApp: 'https://supplier-dev.addtoevent.dev/',
  requestQuotesUrl: `${webUrl}/request-quotes?form=open`,
  browseSuppliersUrl: `${webUrl}/browse-suppliers`,
  blogUrl: `${webUrl}/blog`,
  termsOfUseUrl: `${webUrl}/terms-of-use`,
  privacyAndCookiePolicyUrl: `${webUrl}/privacy-and-cookie-policy`,
  eventOrganiserSupportUrl: 'https://support.addtoevent.co.uk/en/collections/69572-event-organisers',
  loginUrl: `${webUrl}/user/login?destination=user/me`,
  firebase: {
    apiKey: 'AIzaSyDj8ghoa-7I6ERmeknJfY41Rrg1u8Sc8_I',
    authDomain: 'ate-mp-dev.firebaseapp.com',
    databaseURL: 'https://ate-mp-dev.firebaseio.com',
    projectId: 'ate-mp-dev',
    appId: '1:315147417518:web:95ee4268d1ec30b57cfa3f',
    messagingSenderId: '315147417518',
    storageBucket: 'ate-mp-dev.appspot.com'
  },
  vapidPublicKey: 'BDHuXLsPkp3nB2kqQRLuuqgEUGZLtRKdTkz6Nmvl6qN4M5CmDfxOBbEhLqqcSqnGqNbdZa6sjrbF70G7cKH9h6A',
  supportEmailAddress: 'support-team@addtoevent.co.uk'
};
